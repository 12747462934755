import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import { Modal, Box, Typography, Alert, Divider } from '@mui/material';
import { hpsdConfigCallbackRequest, hpsdConfigRequest, trackingHpsdUpdateRequest } from '../services/apiSys';
import CircularWithValueLabel from './CircularProgressWithLabel';
import StatusIcon from './StatusIcon';
import handleError from '../utils/handleError';
import ManualTask from './ManualTask';
import { HpsdTaskTrackingUpdate } from '../interfaces/HpsdTaskTrackingUpdate';
import { HpsdConfigCallback } from '../interfaces/HpsdConfigCallback';
import { HpsdTracking } from '../interfaces/HpsdTracking';
import { Order } from '../interfaces/Order';
import { HpsdConfigBodyRequest } from '../interfaces/HpsdConfigBodyRequest';
import HpsdResultDetail from './HpsdResultDetail';

type Props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  product: string;
  loginOrder: Order;
  // iatInfo: IatInfoData;
  // certification: IatTaskConfigCertification;
};

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  // width: 400,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
  overflow: 'auto',
  maxHeight: '70vh',
};

/**
 * HPSD Component to generate a taskbar and a button to open a Modal to certificate and finish an open task
 * @param open Boolean for the open state of the Finish Task Modal.
 * @param setOpen Method to change the open state of the Finish Task Modal.
 * @param iatInfo The Iat Info data retured from IAT API.
 * @param product The product relating to this task. ex: LP/LP+Router/SWT. 
 * @param certification The certification API to be used.
 * @returns 
 */
export default function FenixFinishTaskSnackbar({ open, setOpen, product, loginOrder }: Props) {
  const [openModal, setOpenModal] = useState(false);
  const [finishResult, setFinishResult] = useState<HpsdTracking>();
  const [certificationTaskId, setCertificationTaskId] = useState<string>();
  const [certificationResult, setCertificationResult] = useState<HpsdConfigCallback>();
  const [error, setError] = useState<string>();

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleCertification = async () => {
    try {
      handleOpenModal();
      const body: HpsdConfigBodyRequest = {
        service: {
          id: loginOrder.specifications.XA_ACCESS_DESIGNATOR,
          category: 'CertificacaoCPE',
          serviceSpecification: 'Internet',
          CommercialOrderId: loginOrder.customerOrderId
        },
        fields: [
          { name: 'NaturezaOrdem', value: 'Alta' },
          { name: 'TIPO_CERTIFICACAO', value: 'AUTOMATICA' },
          { name: 'LOGIN_TECNICO_CERTIFICACAO', value: loginOrder.resourceId },
          { name: 'FORCE_EXECUTE', value: 'Nao' }
        ]
      };
      const resp = await hpsdConfigRequest(body);
      if (resp.data && resp.data.id_result) {
        setCertificationTaskId(resp.data.id_result);
      }

    } catch (error) {
      console.log(error);
      handleError({ error, setError });
    }
  };

  const handleFinishTask = async (certificationResponse: HpsdConfigCallback, statusDescription: string) => {
    try {
      const body: HpsdTaskTrackingUpdate = {
        designador: certificationResponse.event.serviceOrder.serviceOrderItem![0].id,
        product: 'INTERNET',
        status: statusDescription,
        tasks: certificationResponse
      };
      const resp = await trackingHpsdUpdateRequest(body);
      if (resp.data) setFinishResult(resp.data);
    } catch (error) {
      console.log(error);
      handleError({ error, setError });
    }
  };

  //Ping for config callback status
  useEffect(() => {
    if (certificationTaskId) {
      //Method to ping the Hpsd Config Callback
      const pingCallbackStatus = async () => {
        try {
          const resp = await hpsdConfigCallbackRequest(certificationTaskId);
          if (resp.data && Object.keys(resp.data).length > 0) {
            // If the config is finished, clear the interval
            clearInterval(intervalId);
            if (resp.data.event.serviceOrder.serviceOrderItem) {
              const service = resp.data.event.serviceOrder.serviceOrderItem[0].service;
              if (service.category === 'CertificacaoCPE') {
                if (resp.data.event.serviceOrder.state === "failed") {
                  // setLoading(false);
                  setError(`Certificação teve falha na execução: \n${resp.data.event.serviceOrder.errorMessage[0].message}`);
                } else {
                  // setLoading(false);
                  // setSuccess('Configuração realizada com sucesso.');
                  // setHpsdConfigurationCallback(resp.data);
                  setCertificationResult(resp.data);
                  handleFinishTask(resp.data, 'COMPLETED');
                }
              }
            } else {
              setError('Timeout - não recebeu resposta do callback HPSD.');
            }
          }
        } catch (error) {
          handleError({ error, setError });
          clearInterval(intervalId);
          // setLoading(false);
        }
      };

      // Set up a periodic ping every 5 seconds to check if the Config Task is already finished and get the result
      const intervalId = setInterval(async () => {
        // Check task status initially
        pingCallbackStatus();
      }, 5000);

      // Clear the interval when the component is unmounted
      return () => clearInterval(intervalId);
    }
  }, [certificationTaskId]);

  const action = (
    <>
      <Button color="primary" size="small" onClick={handleCertification}>
        Certificar Ordem
      </Button>
    </>
  );

  return (
    <div>
      <Button className='button' onClick={handleCertification}>Certificar e Finalizar Ordem</Button>
      <Snackbar
        sx={{ zIndex: 1302 }}
        // anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        open={open}
        // autoHideDuration={6000}
        onClose={handleClose}
        action={action}
      >
        <Alert onClose={handleClose} action={action} severity='info' sx={{ width: '100%' }}>{`Certificar e finalizar atividade`}</Alert>
      </Snackbar>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
      >
        <Box sx={style} display={'flex'} flexDirection={'column'} alignItems={'center'} width={'70%'}>
          <h3>Certificando e Finalizando a Ordem</h3>
          {/* <Box display={'flex'} justifyContent={'center'}> */}
          {error ? (<p className='error'>{error}</p>)
            : certificationResult ? (
              <>
                {certificationResult.event.serviceOrder.state === 'failed' && (
                  <ManualTask
                    hpsd={{
                      designador: certificationResult.event.serviceOrder.serviceOrderItem![0].id,
                      taskType: 'certification',
                      taskProduct: 'INTERNET',
                    }}
                  />
                )}
                {finishResult && (
                  <Typography >
                    <StatusIcon status={finishResult.status === 'COMPLETED'} text={['Ordem Finalizada', 'Ordem não finalizada']} />
                  </Typography>
                )}
                <Divider flexItem />
                <h4>Resultado da Certificação</h4>
                <HpsdResultDetail service={certificationResult.event.serviceOrder.serviceOrderItem![0].service} />
              </>
            ) : (
              <CircularWithValueLabel maxSecondsTime={30} interval={5000} />
            )}
          {/* </Box> */}
        </Box>
      </Modal>
    </div >
  );
}
