import React, { FormEvent, useCallback, useEffect, useRef, useState } from 'react';
import { InternetFacility, Value } from '../interfaces/InternetFacility';
import { Alert, Backdrop, Box, Button, Card, CardContent, CardHeader, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Paper, Radio, RadioGroup, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import DynamicForm from './DynamicForm';
import DynamicView from './DynamicView';
import { HpsdConfigBodyRequest } from '../interfaces/HpsdConfigBodyRequest';
import { hpsdConfigCallbackRequest, hpsdConfigRequest } from '../services/apiSys';
import handleError from '../utils/handleError';
import { InputCustom } from '../interfaces/InputCustom';
import { Characteristic, Service, ServiceCharacteristic, ValueElement } from '../interfaces/HpsdConfigCallback';
import { extractAttributes } from '../utils/HpsdUtils';
import { ActionsAfterMN05 } from '../enums';
import CircularProgressIndeterminateWithLabel from './CircularProgressIndeterminateWithLabel';

type Props = {
  dataFacility: InternetFacility;
};

type GenericField = {
  name: string;
  value: string;
};

/** Map Vivo1 parameters from API .../tmf-638/facilities */
const plantaExternaVivo1LabelDisplay: Record<string, InputCustom> = {
  'NOME_CTO': { label: 'CAIXA/DGOI', mapRequestName: 'TERMINAL_BOX_NAME', required: true },
  'SPLITTER_PRIMARIO': { label: 'SPLITTER DE 1º NIVEL (SPLITTER1N)', mapRequestName: 'SPLITTER_FIRST_LEVEL_ID', required: true },
  'SPLITTER_SECUNDARIO': { label: 'SPLITTER DE 2º NIVEL (SPLITTER2N)', mapRequestName: 'SPLITTER_SECOND_LEVEL_ID', required: true },
  'CABO_ALIMENTADOR': { label: 'CABO (ALIMENTADOR)', mapRequestName: 'CABLE_IN', required: true },
  'FIBRA_ALIMENTADOR': { label: 'FIBRA PRIMÁRIA/ALIMENTADOR', mapRequestName: 'FIBER_IN', required: true },
  'FIBRA_DISTRIBUIDOR': { label: 'FIBRA SECUNDÁRIA (FIBER CABLE)', mapRequestName: 'FIBER_CABLE' },
};

/** Map Vivo2 parameters from API .../tmf-638/facilities */
const plantaExternaVivo2LabelDisplay: Record<string, InputCustom> = {
  'CABINET_ID': { label: 'ARD (ARMARIO)', mapRequestName: 'CABINET_ID', required: true },
  'TIPO_CTO': { label: 'TIPO DE EQUIPAMENTO', mapRequestName: 'TERMINAL_BOX_TYPE', },
  'SPLITTER_PRIMARIO': { label: 'SPLITTER DE 1º NIVEL (SPLITTER1N)', mapRequestName: 'SPLITTER_FIRST_LEVEL_ID', required: true },
  'SPLITTER_SECUNDARIO': { label: 'SPLITTER DE 2º NIVEL (SPLITTER2N)', mapRequestName: 'SPLITTER_SECOND_LEVEL_ID', required: true },
  'NOME_CTO': { label: 'CTO (NOME DO TERMINAL BOX)', mapRequestName: 'TERMINAL_BOX_NAME', required: true },
  'PORTA_SPLITTER_SECUNDARIO': { label: 'FIBRA SECUNDÁRIA (FIBER CABLE)', mapRequestName: 'FIBER_CABLE' },
};

/** Map Vivo1 parameters from API .../tmf-641 category MN07 */
const displayNameMappingVivo1: { key: string; displayName: string; }[] = [
  { key: 'TERMINAL_BOX_NAME', displayName: 'TERMINAL BOX' },
  { key: 'TERMINAL_BOX_TYPE', displayName: 'TIPO DE EQUIPAMENTO' },
  { key: 'SPLITTER_FIRST_LEVEL_ID', displayName: 'SPLITTER DE 1º NIVEL (SPLITTER1N)' },
  { key: 'SPLITTER_SECOND_LEVEL_ID', displayName: 'SPLITTER DE 2º NIVEL (SPLITTER2N)' },
  { key: 'CABLE_IN', displayName: 'CABO (ALIMENTADOR)' },
  { key: 'FIBER_IN', displayName: 'FIBRA PRIMÁRIA/ALIMENTADOR' },
  { key: 'FIBER_CABLE', displayName: 'FIBRA SECUNDÁRIA (FIBER CABLE)' },
];

/** Map Vivo2 parameters from API .../tmf-641 category MN07 */
const displayNameMappingVivo2: { key: string; displayName: string; }[] = [
  { key: 'SPLITTER_FIRST_LEVEL_ID', displayName: 'SPLITTER DE 1º NIVEL (SPLITTER1N)' },
  { key: 'SPLITTER_SECOND_LEVEL_ID', displayName: 'SPLITTER DE 2º NIVEL (SPLITTER2N)' },
  { key: 'CABINET_ID', displayName: 'ARD (ARMARIO)' },
  { key: 'TERMINAL_BOX_TYPE', displayName: 'TIPO DE EQUIPAMENTO' },
  { key: 'TERMINAL_BOX_NAME', displayName: 'CTO (NOME DO TERMINAL BOX)' },
  // { key: 'PORTA_SPLITTER_SECUNDARIO', displayName: 'FIBRA SECUNDARIA (FIBER CABLE)' },
  { key: 'FIBER_CABLE', displayName: 'FIBRA SECUNDARIA (FIBER CABLE)' },
];

const motives = [
  'Porta Ocupada',
  'Rede Saturada',
  'Distância',
  'Porta com defeito',
  'Acerto de Cadastro',
  'Alívio de Rede',
];

/** Format the data for the request of /tmf-641 API */
const formatRequestFields = (data: InternetFacility, mapping: Record<string, InputCustom>) => {
  const characteristics = data.serviceCharacteristic.find((service) => service.name === "PLANTA EXTERNA")?.value;
  let fields: any = [];

  Object.keys(mapping).forEach((key) => {
    if (characteristics && characteristics.hasOwnProperty(key)) {
      fields = [...fields, { name: mapping[key].mapRequestName, value: characteristics[key as keyof Value] }];
    }
  });

  return fields;
};

const ManobraRecursoDisplay = ({ dataFacility }: Props) => {

  const [dataFacilityUpdated, setDataFacilityUpdated] = useState<InternetFacility>(dataFacility);
  const [showManobraManualForm, setShowManobraManualForm] = useState(true);
  const [motive, setMotive] = useState<string>('');
  const [resultId, setResultId] = useState<string>();
  const [fieldsOptions, setFieldsOptions] = useState<ValueElement[] | undefined>();
  const [selectedRow, setSelectedRow] = useState<number | null>(null);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState<string>('');
  const [error, setError] = useState<string>('');
  const notificationRef = useRef<HTMLDivElement>(null);
  const [hpsdStatusCallback, setHpsdStatusCallback] = useState<string>();

  /**
   * Get the attributes of Geral Characteristics returned from /tmf-638/facilities
   */
  const geralCharacteristic = useCallback(() => {
    return dataFacility.serviceCharacteristic.find((char) => char.name === 'GERAL')?.value;
  }, [dataFacility])(); // Invoking the function immediately to get the initial values

  /**
   * Get the attributes of Planta Externa Characteristics returned from /tmf-638/facilities
   * Get it's index to update the data in the dataFacilityUpdated
   */
  const { plantaExternaIndexOf, plantaExternaCharacteristic }: { plantaExternaIndexOf: number; plantaExternaCharacteristic: Value; } = useCallback(() => {
    let index = dataFacility.serviceCharacteristic.findIndex((service) => service.name === "PLANTA EXTERNA");
    return { plantaExternaIndexOf: index, plantaExternaCharacteristic: dataFacility.serviceCharacteristic[index].value };
  }, [dataFacility])(); // Invoking the function immediately to get the initial values

  /**
     * Get the attributes of Acesso Characteristics returned from /tmf-638/facilities
     */
  const acessoCharacteristic = useCallback(() => {
    return dataFacility.serviceCharacteristic.find((char) => char.name === 'ACESSO')?.value;
  }, [dataFacility])(); // Invoking the function immediately to get the initial values

  /**
   * Change the key name of attributes adding the prefix _PARA
   */
  const buildManobraPARAFields = useCallback((characteristcs: Characteristic[] | ServiceCharacteristic[]) => {
    let serviceFields: GenericField[] = [];
    // const serviceCharacteristic = result.event.serviceOrder.serviceOrderItem[0].service.serviceCharacteristic;
    characteristcs.forEach((characteristic) => {
      switch (characteristic.name) {
        case "SPLITTER_FIRST_LEVEL_ID":
          serviceFields = ([...serviceFields, { name: "SPLITTER_FIRST_LEVEL_ID_PARA", value: characteristic.value as string }]);
          break;
        case "SPLITTER_SECOND_LEVEL_ID":
          serviceFields = ([...serviceFields, { name: "SPLITTER_SECOND_LEVEL_ID_PARA", value: characteristic.value as string }]);
          break;
        case "CABLE_ID":
          serviceFields = ([...serviceFields, { name: "CABLE_ID_PARA", value: characteristic.value as string }]);
          break;
        case "CABLE_IN":
          serviceFields = ([...serviceFields, { name: "CABLE_IN_PARA", value: characteristic.value as string }]);
          break;
        case "FIBER_IN":
          serviceFields = ([...serviceFields, { name: "FIBER_IN_PARA", value: characteristic.value as string }]);
          break;
        case "FIBER_CABLE":
          serviceFields = ([...serviceFields, { name: "FIBER_CABLE_PARA", value: characteristic.value as string }]);
          break;
        case "FIBER_CABLE_TYPE":
          serviceFields = ([...serviceFields, { name: "FIBER_CABLE_TYPE_PARA", value: characteristic.value as string }]);
          break;
        case "CABINET_ID":
          serviceFields = ([...serviceFields, { name: "CABINET_ID_PARA", value: characteristic.value as string }]);
          break;
        case "CABINET_NAME":
          serviceFields = ([...serviceFields, { name: "CABINET_NAME_PARA", value: characteristic.value as string }]);
          break;
        case "TERMINAL_BOX_NAME":
          serviceFields = ([...serviceFields, { name: "TERMINAL_BOX_NAME_PARA", value: characteristic.value as string }]);
          break;
        case "TERMINAL_BOX_TYPE":
          serviceFields = ([...serviceFields, { name: "TERMINAL_BOX_TYPE_PARA", value: characteristic.value as string }]);
          break;
        case "LATERAL_CABLE":
          serviceFields = ([...serviceFields, { name: "LATERAL_CABLE_PARA", value: characteristic.value as string }]);
          break;
        default:
          break;
      }
    });
    // setManobraFields([...manobraFields, ...serviceFields]);
    return serviceFields;
  }, []);

  /**
   * Check if the response of /tmf-641 category MN05 is valid
   */

  const actionToTakeAfterMN05 = useCallback((service: Service): ActionsAfterMN05 | undefined => {
    if (!service.serviceCharacteristic) {
      return;
    }

    const appliedCapacityAmount = service.serviceCharacteristic.find((char) => char.name === 'appliedCapacityAmount');
    const capacityAmmount = appliedCapacityAmount ? +(appliedCapacityAmount.value as string) : undefined;
    const terminalBoxType = service.serviceCharacteristic.find((char) => char.name === 'terminalboxtype')?.value as string;
    const resources = service.serviceCharacteristic.find((service) => service.name === 'resources')?.value as ValueElement[];

    if (capacityAmmount !== undefined && capacityAmmount > 0) {
      return ActionsAfterMN05.SHOW_MN_05_OPTIONS;
    }

    if ((capacityAmmount === undefined || capacityAmmount === 0) &&
      (resources === undefined || resources.length === 0)) {
      return ActionsAfterMN05.END_MANEUVER_EMPTY_RESOURCE;
    }

    // When TERMINAL_BOX_TYPE is property box (caixa predial)
    const propertyBoxNames = ["Splitter", "Terminal Optico", "Terminal Óptico", "TO"];

    if (propertyBoxNames.includes(terminalBoxType)) {
      return ActionsAfterMN05.END_MANEUVER_PROPERTY_BOX;
    }

    return ActionsAfterMN05.SHOW_MN_07_OPTIONS;
  }, []);

  /**
   * Requests the api /tmf-641 category MN07 to get the valid fields
   */
  const searchManobraAvailableFields = useCallback(async () => {
    try {
      const body: HpsdConfigBodyRequest = {
        service: {
          id: dataFacility.id,
          category: "ManobraRecursos.MN07",
          serviceOrderItem: "serviceOrderItem_ID1",
          serviceSpecification: "Internet"
        },
        fields: [
          { name: "NaturezaOrdem", value: "Manobra" }
        ]
      };

      const resp = await hpsdConfigRequest(body);
      setResultId(resp.data?.id_result);
    } catch (error) {
      handleError({ error, setError });
      setLoading(false);
    }
  }, [dataFacility]);

  /**
   * Execute the request to api /tmf-641 category MN14
   */
  const executeManobra = useCallback(async (fieldsAttributes: Characteristic[] | ServiceCharacteristic[]) => {
    try {
      if (plantaExternaCharacteristic && acessoCharacteristic) {
        const plantaExternaDEFields = extractAttributes(plantaExternaCharacteristic, ['SPLITTER_PRIMARIO'], { SPLITTER_PRIMARIO: 'SPLITTER_FIRST_LEVEL_ID_DE' }) as { name: string, value: string; }[];
        const acessoDEFields = extractAttributes(acessoCharacteristic, ['HOSTNAME_OLT', 'ID_GPON'], { HOSTNAME_OLT: 'OLT_NAME_DE', ID_GPON: 'PORTA_OLT_DE' }) as { name: string, value: string; }[];

        const resources = (fieldsAttributes as ServiceCharacteristic[]).find((service: ServiceCharacteristic) => service.name === 'resources')?.value as ValueElement[];

        let paraFields = [];
        if (resources && resources.length) {
          paraFields = buildManobraPARAFields(resources[0].characteristic);
        } else {
          paraFields = buildManobraPARAFields(fieldsAttributes);
        }

        const body: HpsdConfigBodyRequest = {
          service: {
            id: dataFacility.id,
            category: "ManobraRecursos.MN14",
            // serviceOrderItem: "serviceOrderItem_ID1",
            serviceSpecification: "Internet"
          },
          fields: [
            ...plantaExternaDEFields,
            ...acessoDEFields,
            ...paraFields,
            { name: "NaturezaOrdem", value: "Manobra" },
            { name: 'REALLOCATION_REASON', value: motive },
            { name: 'REALLOCATION_REASON_TEMP', value: motive },
          ]
        };
        const resp = await hpsdConfigRequest(body);
        setResultId(resp.data?.id_result);
        console.log('Manobra Iniciada');
      }
    } catch (error) {
      handleError({ error, setError });
      setLoading(false);
    }
  }, [dataFacility, plantaExternaCharacteristic, acessoCharacteristic, motive, buildManobraPARAFields]);

  /**
   * Scroll to the notification
   */
  useEffect(() => {
    if (notificationRef.current) {
      notificationRef.current.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
    }
  }, [error, success]);

  //Method to ping the Hpsd Config Callback
  const pingConfigStatus = async (resultId: string, intervalId?: NodeJS.Timer) => {
    try {
      const resp = await hpsdConfigCallbackRequest(resultId);
      if (resp.data && Object.keys(resp.data).length > 0) {
        // If the config is finished, clear the interval
        clearInterval(intervalId);
        if (resp.data.event.serviceOrder.serviceOrderItem) {
          const service = resp.data.event.serviceOrder.serviceOrderItem[0].service;

          if (service.category === 'ManobraRecursos.MN05') {
            setShowManobraManualForm(false);
            let action = actionToTakeAfterMN05(service);
            setHpsdStatusCallback(resp.data.event.serviceOrder.state);

            if (action === ActionsAfterMN05.SHOW_MN_05_OPTIONS) {
              console.log("Exibição Opções Manobra MN05");
              setFieldsOptions(service.serviceCharacteristic.find((service) => service.name === 'resources')?.value as ValueElement[]);
              setLoading(false);
            } else if (action === ActionsAfterMN05.SHOW_MN_07_OPTIONS) {
              console.log("Exibição Opções Manobra MN07");
              setError('Posição inserida indisponível. Selecione uma das opções sugeridas.');
              await searchManobraAvailableFields();
            } else if (action === ActionsAfterMN05.END_MANEUVER_PROPERTY_BOX) {
              setError(resp.data.event.serviceOrder.description ?? 'Recurso não disponível para execução da manobra predial.');
              setLoading(false);
            } else if (action === ActionsAfterMN05.END_MANEUVER_EMPTY_RESOURCE) {
              setError(resp.data.event.serviceOrder.description ?? 'Recurso não disponível para execução da manobra.');
              setLoading(false);
            }
          } else if (service.category === 'ManobraRecursos.MN07') {
            setShowManobraManualForm(false);
            setFieldsOptions(service.serviceCharacteristic.find((service) => service.name === 'resources')?.value as ValueElement[]);
            setLoading(false);
          } else if (service.category === 'ManobraRecursos.MN14') {
            const statusManobra = resp.data.event.serviceOrder.state;
            if (statusManobra && statusManobra === 'completed') {
              console.log('Manobra Finalizada com sucesso.');
              setSuccess('Manobra finalizada com sucesso.');
            } else {
              setError(resp.data.event.serviceOrder.description ?? 'Manobra finalizada com erro.');
            }
            setLoading(false);
          }
        } else {
          setError('Timeout não recebeu resposta do callback HPSD.');
        }

        return resp.data;
      }
    } catch (error) {
      handleError({ error, setError });
      clearInterval(intervalId);
      setLoading(false);
      // setResultId('');
    }
  };

  /**
   * Request callback response from the config
   */
  useEffect(() => {
    if (resultId) {

      // Set up a periodic ping every 5 seconds to check if the Config Task is already finished and get the result
      const intervalId = setInterval(async () => {
        // Check task status initially
        pingConfigStatus(resultId, intervalId);
      }, 5000);

      // Clear the interval when the component is unmounted
      return () => clearInterval(intervalId);
    }
  }, [resultId, searchManobraAvailableFields, actionToTakeAfterMN05, executeManobra]);

  /**
   * Handle the change in options of the available fields returned from /tmf-641 category MN07
   * @param event 
   */
  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedRow(parseInt(event.target.value));
  };

  /**
   * Updated the dataFacilityUpdated with the changes made by the user
   * @param value 
   * @param key 
   */
  const handleInputChangeForm = (value: string | number, key: string) => {
    const updatedServiceCharacteristic = [...dataFacilityUpdated.serviceCharacteristic]; // Create a copy of the array
    updatedServiceCharacteristic[plantaExternaIndexOf] = {
      ...updatedServiceCharacteristic[plantaExternaIndexOf],
      value: {
        ...updatedServiceCharacteristic[plantaExternaIndexOf].value,
        [key]: value
      }
    };
    setDataFacilityUpdated({
      ...dataFacility,
      serviceCharacteristic: updatedServiceCharacteristic
    });
  };

  /** In case of the Manual Manobra executed, it must first validate the fields set by the user via /tmf-641 category MN05 */
  const handleSubmitManobraManual = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setError('');
    setResultId('');
    try {
      if (dataFacilityUpdated) {
        console.log('Validar Manobra');
        setLoading(true);
        const mapping = dataFacilityUpdated.place.network_owner === 'VIVO1' ? plantaExternaVivo1LabelDisplay : plantaExternaVivo2LabelDisplay;
        const fields = formatRequestFields(dataFacilityUpdated, mapping);
        const body: HpsdConfigBodyRequest = {
          service: {
            id: dataFacilityUpdated.id,
            category: "ManobraRecursos.MN05",
            serviceOrderItem: "Internet",
            serviceSpecification: 'Internet'
          },
          fields: [
            ...fields,
            { name: 'NaturezaOrdem', value: 'Manobra' }
          ]
        };

        const resp = await hpsdConfigRequest(body);
        setResultId(resp.data?.id_result);
      }
    } catch (error) {
      handleError({ error, setError });
      setLoading(false);
    }
  };

  /** In case of Automated Manobra, where the user must select one option from the available fields options  */
  const handleSubmitManobraAuto = async () => {
    setResultId('');
    setError('');
    try {
      setLoading(true);
      if (selectedRow === null) {
        setError('Selecione a posição desejada.');
      }
      else if (fieldsOptions) {
        executeManobra(fieldsOptions[selectedRow].characteristic);
      }
    } catch (error) {
      handleError({ error, setError });
      setLoading(false);
    }
  };

  const handleGetCallback = () => {
    if (resultId) pingConfigStatus(resultId);
  };

  return (
    <Box width={'100%'}>
      <Stack sx={{ width: '100%', marginTop: 1 }} spacing={2}>
        <div ref={notificationRef}>
          {error && (
            <Alert severity="error" variant='filled'>
              {error}
            </Alert>
          )}
          {success && (
            <Alert severity="success" variant='filled'>
              {success}
            </Alert>
          )}
        </div>
      </Stack>
      {hpsdStatusCallback === 'held' && (
        <Button
          className='button'
          variant="contained"
          disabled={loading}
          onClick={handleGetCallback}
        >
          Reconsultar Callback
        </Button>
      )}
      {showManobraManualForm && geralCharacteristic && plantaExternaCharacteristic && (
        <Box
          width="100%"
          marginTop={1}
          component="form"
          onSubmit={handleSubmitManobraManual}
        >
          <Grid container spacing={2} >
            <Grid item xs={12}>
              <Card>
                <CardContent sx={{
                  '& .MuiTextField-root': { m: 1 },
                  display: 'flex',
                  justifyContent: 'space-around'
                }}>
                  <TextField
                    label="Tipo Acesso"
                    value={geralCharacteristic.TIPO_ACESSO}
                    variant='standard'
                    InputProps={{
                      readOnly: true
                    }}
                    fullWidth
                  />
                  <TextField
                    label="Rede"
                    value={geralCharacteristic.PLACE_RESERVED?.network_owner}
                    variant='standard'
                    InputProps={{
                      readOnly: true
                    }}
                    fullWidth
                  />
                  <TextField
                    label="Serviço"
                    value={geralCharacteristic.TIPO_SERVICO}
                    variant='standard'
                    InputProps={{
                      readOnly: true
                    }}
                    fullWidth
                  />
                  <TextField
                    label="Razão Social"
                    value={geralCharacteristic.RAZAO_SOCIAL}
                    variant='standard'
                    InputProps={{
                      readOnly: true
                    }}
                    fullWidth
                  />
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={6} display={'grid'}>
              <Card className='card'>
                <CardHeader
                  title="Posição Atual"
                  titleTypographyProps={{ textAlign: "center" }}
                />
                <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
                  <DynamicView
                    data={plantaExternaCharacteristic}
                    labelMap={dataFacility.place.network_owner === 'VIVO1' ? plantaExternaVivo1LabelDisplay : plantaExternaVivo2LabelDisplay}
                  />
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={6} display={'grid'}>
              <Card className='card'>
                <CardHeader
                  title="Posição Desejada"
                  titleTypographyProps={{ textAlign: "center" }}
                />
                <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
                  <DynamicForm
                    data={dataFacilityUpdated.serviceCharacteristic[plantaExternaIndexOf].value}
                    labelMap={dataFacility.place.network_owner === 'VIVO1' ? plantaExternaVivo1LabelDisplay : plantaExternaVivo2LabelDisplay}
                    handleInputChange={handleInputChangeForm}
                  />
                  <FormControl fullWidth margin='normal'>
                    <InputLabel>Motivo</InputLabel>
                    <Select
                      value={motive}
                      label="Motivo"
                      required
                      onChange={(e) => setMotive(e.target.value as string)}
                    >
                      {motives.map((motive) => (
                        <MenuItem
                          key={motive}
                          value={motive}
                        >
                          {motive}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} textAlign="center">
              <Button
                className='button'
                type='submit'
                variant="contained"
                disabled={loading} // || compare(dataFacility, dataFacilityUpdated)
              >
                {loading ? `Consultando...` : `Consulta disponibilidade`}
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}
      {!showManobraManualForm && fieldsOptions && dataFacility && plantaExternaCharacteristic && (
        <Box
          width="100%"
          marginTop={1}
        >
          <Card className='card'>
            <CardHeader
              title="Posição Atual"
              titleTypographyProps={{ textAlign: "center" }}
            />
            <CardContent
              sx={{
                '& .MuiTextField-root': { m: 1 },
                display: 'flex',
                justifyContent: 'space-around'
              }}
            >
              <DynamicView
                data={plantaExternaCharacteristic}
                labelMap={dataFacility.place.network_owner === 'VIVO1' ? plantaExternaVivo1LabelDisplay : plantaExternaVivo2LabelDisplay}
                variant='standard'
              />
            </CardContent>
          </Card>
          <TableContainer component={Paper} sx={{ marginTop: 1 }}>
            <Typography variant='h6' textAlign={'center'}>
              Posição Desejada
            </Typography>
            <Table>
              <TableHead>
                <TableRow>
                  {(dataFacility.place.network_owner === 'VIVO1' ? displayNameMappingVivo1 : displayNameMappingVivo2).map((item, index) => (
                    <TableCell key={index}>{item.displayName}</TableCell>
                  ))}
                  <TableCell>Selecione</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {fieldsOptions.map((row, rowIndex) => (
                  <TableRow key={rowIndex}>
                    {(dataFacility.place.network_owner === 'VIVO1' ? displayNameMappingVivo1 : displayNameMappingVivo2).map((item, cellIndex) => {
                      const charItem = row.characteristic.find(char => char.name === item.key);
                      return (
                        <TableCell key={cellIndex}>{charItem ? charItem.value : ''}</TableCell>
                      );
                    })}
                    <TableCell>
                      <RadioGroup value={selectedRow} onChange={handleRadioChange}>
                        <FormControlLabel label={''} value={rowIndex} control={<Radio />} />
                      </RadioGroup>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Card className='card' sx={{ marginTop: 1 }}>
            <CardContent>
              <FormControl fullWidth margin='normal'>
                <InputLabel>Motivo</InputLabel>
                <Select
                  value={motive}
                  label="Motivo"
                  required
                  onChange={(e) => setMotive(e.target.value as string)}
                >
                  {motives.map((motive) => (
                    <MenuItem
                      key={motive}
                      value={motive}
                    >
                      {motive}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </CardContent>
          </Card>
          <Box
            display={'flex'}
            justifyContent={'center'}
            marginTop={1}
          >
            <Button
              className='button'
              onClick={handleSubmitManobraAuto}
              variant="contained"
              disabled={loading}
            >
              {loading ? `Manobrando...` : `Manobrar`}
            </Button>
          </Box>
        </Box>
      )}
      {loading && (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 100 }}
          open={loading}
        >
          {/* <CircularProgress color="inherit" /> */}
          {/* <CircularWithValueLabel maxSecondsTime={180} interval={5000} /> */}
          <CircularProgressIndeterminateWithLabel maxSecondsTime={600} descriptions={
            ['Processo em andamento...', 'Processo demorando mais que o normal...', 'Provável falha na consulta por timeout...']
          }
          />
        </Backdrop>
      )}
    </Box>
  );
};

export default ManobraRecursoDisplay;