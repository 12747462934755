import { Alert, Autocomplete, Backdrop, Box, Button, Card, CardContent, Checkbox, CircularProgress, Divider, FormControlLabel, Grid, Stack, Step, StepLabel, Stepper, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, TextField, Typography } from '@mui/material';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import React, { ChangeEvent, FormEvent, useCallback, useEffect, useState } from 'react';
import { InternetFacility, Value } from '../interfaces/InternetFacility';
import { InputCustom } from '../interfaces/InputCustom';
import RecursiveTextField from './RecursiveTextField';
import TaskTrackingDetailCard from './TaskTrackingDetailCard';
import handleError from '../utils/handleError';
import { hpsdRedesenhoBackupRequest, hpsdConfigCallbackRequest, hpsdConfigRequest, orderLoginRequest, trackingHpsdListRequest, trackingHpsdUpdateRequest, hpsdRedesenhoTaskRequest, hpsdRedesenhoResultRequest, doneCertificationRequest } from '../services/apiSys';
import DynamicView from './DynamicView';
import { HpsdTracking, Task } from '../interfaces/HpsdTracking';
import { HpsdTaskTrackingUpdate, HpsdTask, HpsdTaskResult } from '../interfaces/HpsdTaskTrackingUpdate';
import { Field, HpsdConfigBodyRequest } from '../interfaces/HpsdConfigBodyRequest';
import { Roles, UnidadeVelocidade } from '../utils/EnumUtils';
import CircularWithValueLabel from './CircularProgressWithLabel';
import { filesInfoRequest } from '../services/apiWeb';
import { HpsdConfigCallback } from '../interfaces/HpsdConfigCallback';
import HpsdResultDetail from './HpsdResultDetail';
import FenixFinishTaskSnackbar from './FenixFinishTaskSnackbar';
import { Order } from '../interfaces/Order';
import FormGroups from './FormGroups';
import ProtectedComponent from './ProtectedComponent';
import TaskCircuitReworkDetailCard from './TaskCircuitReworkDetailCard';
import { useUserContext } from '../contexts/UserContext';
import { Label } from '@mui/icons-material';

enum MainTabs {
  "Informações de Topologia" = 1,
  "Configuração",
  "Diagnóstico",
  "DoneCertification",
  "CircuitRework",
}


type Props = {
  facilityInfo: InternetFacility;
};

type TabsInfo = {
  id: number;
  label: string;
  disabled?: boolean;
};

type TopologiaTabsInfo = {
  key: string;
  label: string;
};

type Vendor = {
  name: string;
  models: Model[];
};

type Model = {
  name: string;
};

type TabPanelProps = {
  children?: React.ReactNode;
  index: number;
  value: number;
};

type ManualConfig = {
  log: string;
  justify: string;
};

type AutoConfig = {
  loginTecnico: string;
  hostname: string;
};

type SubGroup = {
  title: string,
  fields?: Record<string, InputCustom>;
  customFields?: boolean;
};

// SideBar Mapping

const sideBarDisplay: Record<string, InputCustom> = {
  'id': { label: 'Designador' },
  'place.city': { label: 'Cidade' },
  'place.postCode': { label: 'CEP' },
  'place.streetName': { label: 'Rua' },
  'place.streetNr': { label: 'Número' },
};

const sideBarGeralLabelDisplay: Record<string, InputCustom> = {
  CATEGORIA_SERVICO: { label: 'Categoria do Serviço' },
  'PLACE_RESERVED.network_owner': { label: 'Rede' },
  TIPO_SERVICO: { label: 'Tipo de Serviço' },
  TIPO_CLIENTE: { label: 'Tipo de Cliente' },
  TIPO_ACESSO: { label: 'Tipo de Acesso' },
  SEGMENTO: { label: 'Segmento' },
  'RAZAO_SOCIAL': { label: 'Razão Social' },
  'CNPJ': { label: 'CNPJ' },
};

// Services Label Mapping

const geralLabelDisplay: Record<string, InputCustom> = {
  CATEGORIA_SERVICO: { label: 'Categoria do Serviço', readonly: true },
  // CNL: { label: 'CNL', readonly: true },
  CNPJ: { label: 'CNPJ', readonly: true },
  DESIGNADOR_ACESSO: { label: 'Designador de Acesso', readonly: true },
  DESIGNADOR_PRODUTO: { label: 'Designador de Produto', readonly: true },
  'PLACE_RESERVED.centralOffice': { label: 'Escritório de Serviço', readonly: true },
  'PLACE_RESERVED.city': { label: 'Cidade', readonly: true },
  'PLACE_RESERVED.cnl': { label: 'CNL', readonly: true },
  'PLACE_RESERVED.cnlAcronym': { label: 'Acrônimo', readonly: true },
  'PLACE_RESERVED.locality': { label: 'Localidade', readonly: true },
  'PLACE_RESERVED.microarea': { label: 'Microarea', readonly: true },
  'PLACE_RESERVED.network_owner': { label: 'Rede', readonly: true },
  TIPO_ACESSO: { label: 'Tipo de Acesso', readonly: true },
  TIPO_CLIENTE: { label: 'Tipo de Cliente', readonly: true },
  TELEPHONIC_AREA: { label: 'Area Telefônica', readonly: true },
  TIPO_SERVICO: { label: 'Tipo de Serviço', readonly: true },
  DOWNLOAD_SPEED: { label: 'Velocidade de Downstream', value: (data) => `${(data.VELOCIDADE_DOWN)} ${UnidadeVelocidade[(data.UNIDADE_VELOCIDADE_DOWN) as keyof typeof UnidadeVelocidade]}` },
  UPLOAD_SPEED: { label: 'Velocidade de Upstream', value: (data) => `${(data.VELOCIDADE_UP ?? '')} ${UnidadeVelocidade[(data.UNIDADE_VELOCIDADE_UP) as keyof typeof UnidadeVelocidade] ?? ''}` },
};

const geralHistoricLabelDisplay: Record<string, InputCustom> = {
  HISTORICO_STATUS_CICLO_DE_VIDA: { label: 'Histórico', readonly: true },
};

const ipsLabelDisplay: Record<string, InputCustom> = {
  b2bWanIPV4CPENetworkAddress: { label: 'WAN IP CPE' },
  b2bWanIPV4PENetworkAddress: { label: 'WAN IP PE' },
  b2bWanIPV4RedeNetworkAddress: { label: 'WAN NET ADDR' },
  b2bWanIPV4BroadcastNetworkAddress: { label: 'WAN BROADCAST ADDR' },
  cidrIpWanCpe: { label: 'WAN CPE PREFIX' },
  cidrIpWanPe: { label: 'WAN PE PREFIX' },
  b2bLanIPV4PENetworkAddress: { label: 'LAN NET ADDR' },
  b2bLanIPV4BroadcastNetworkAddress: { label: 'LAN BROADCAST ADDR' },
  b2bLanIPV4CPENetworkAddress: { label: 'LAN IP CPE' },
  b2bLanInitIPV4ClientNetworkAddress: { label: 'LAN FIRST IP' },
  b2bLanFinalIPV4ClientNetworkAddress: { label: 'LAN LAST IP' },
  cidrIpLanCpe: { label: 'LAN PREFIX' },
  b2bLoopbackIPV4NetworkAddress: { label: 'Loopback IP ADDR' },
  cidrIpLoopback: { label: 'Loopback PREFIX' },
  b2bWanIPV6RedeNetworkAddress: { label: 'WAN NET ADDRv6' },
  b2bWanIPV6BroadcastNetworkAddress: { label: 'WAN BROADCAST ADDRv6' },
  b2bWanIPV6CPENetworkAddress: { label: 'WAN IPv6 CPE' },
  b2bWanIPV6PENetworkAddress: { label: 'WAN IPv6 PE' },
  b2bLanIPV6CPENetworkAddress: { label: 'LAN IPv6 CPE' },
  b2bLanIPV6PENetworkAddress: { label: 'LAN IPv6 PE' },
  b2bLanInitIPV6ClientNetworkAddress: { label: 'LAN FIRST IPv6' },
  b2bLanFinalIPV6ClientNetworkAddress: { label: 'LAN LAST IPv6' },
  cidrIpv6LanCpe: { label: 'LAN PREFIXv6' },
  cidrIpv6WanCpe: { label: 'WAN CPE PREFIXv6' },
  cidrIpv6WanPe: { label: 'WAN PE PREFIXv6' },
};

const ipsIpv4LanCpeDisplay: Record<string, InputCustom> = {
  b2bLanIPV4PENetworkAddress: { label: 'Endereço de Rede' },
  b2bLanIPV4BroadcastNetworkAddress: { label: 'Endereço de Broadcast' },
  cidrIpLanCpe: { label: 'Prefixo de Rede' },
  b2bLanIPV4CPENetworkAddress: { label: 'Endereço da interface LAN do Router' },
  b2bLanInitIPV4ClientNetworkAddress: { label: 'Primeiro endereço válido do cliente' },
  b2bLanFinalIPV4ClientNetworkAddress: { label: 'Último endereço válido do cliente' },
};

const ipsIpv6LanCpeDisplay: Record<string, InputCustom> = {
  b2bLanIPV6PENetworkAddress: { label: 'Endereço de Rede' },
  cidrIpv6LanCpe: { label: 'Prefixo de Rede' },
  b2bLanIPV6CPENetworkAddress: { label: 'Endereço da interface LAN do Router' },
  b2bLanInitIPV6ClientNetworkAddress: { label: 'Primeiro endereço válido do cliente' },
  b2bLanFinalIPV6ClientNetworkAddress: { label: 'Último endereço válido do cliente' },
};

const ipsIpv4WanCpeLabelDisplay: Record<string, InputCustom> = {
  b2bWanIPV4RedeNetworkAddress: { label: 'Endereço de Rede' },
  b2bWanIPV4BroadcastNetworkAddress: { label: 'Endereço de Broadcast' },
  cidrIpWanCpe: { label: 'Prefixo de Rede' },
  b2bWanIPV4CPENetworkAddress: { label: 'Interface WAN do Router Cliente' },
  b2bWanIPV4PENetworkAddress: { label: 'Interface WAN do Router Vivo' },
};

const ipsIpv6WanCpeLabelDisplay: Record<string, InputCustom> = {
  b2bWanIPV6RedeNetworkAddress: { label: 'Endereço de Rede' },
  b2bWanIPV6BroadcastNetworkAddress: { label: 'Endereço de Broadcast' },
  cidrIpv6WanCpe: { label: 'Prefixo de Rede' },
  b2bWanIPV6CPENetworkAddress: { label: 'Interface WAN do Router Cliente' },
  b2bWanIPV6PENetworkAddress: { label: 'Interface WAN do Router Vivo' },
};

const ipsLoopbackLabelDisplay: Record<string, InputCustom> = {
  b2bLoopbackIPV4NetworkAddress: { label: 'Endereço Loopback Router IPV4' },
  cidrIpLoopback: { label: 'Prefixo Loopback IPV4' },
};

const plantaExternaLabelDisplay: Record<string, InputCustom> = {
  'ARMARIO': { label: 'Armário', readonly: true },
  'CABINET_ID': { label: 'CabinetId' },
  'CABO_ALIMENTADOR': { label: 'Cabo Alimentador' },
  'CABO_DISTRIBUIDOR': { label: 'Cabo Distribuidor' },
  'NOME_CTO': { label: 'Nome da CTO', readonly: true },
  'TIPO_CTO': { label: 'Tipo CTO', readonly: true },
  'TIPO_ACESSO': { label: 'Tipo do Acesso', readonly: true },
  'ENDERECO_CTO': { label: 'Nome CTO', value: (data) => `${data.ENDERECO_CTO.streetName}, ${data.ENDERECO_CTO.streetNr}` },
  'FIBRA_ALIMENTADOR': { label: 'Fibra Alimentador' },
  'FIBRA_DISTRIBUIDOR': { label: 'Fibra Distribuidor' },
  'SPLITTER_PRIMARIO': { label: 'Splitter Primário' },
  'PORTA_SPLITTER_PRIMARIO': { label: 'Porta Splitter Primário' },
  'ENDERECO_SPLITTER_PRIMARIO': { label: 'Endereço Splitter Primario', value: (data) => `${data.ENDERECO_SPLITTER_PRIMARIO.streetName}, ${data.ENDERECO_SPLITTER_PRIMARIO.streetNr}` },
  'SPLITTER_SECUNDARIO': { label: 'Splitter Secundário' },
  'PORTA_SPLITTER_SECUNDARIO': { label: 'Splitter Secundário' },
  'ENDERECO_SPLITTER_SECUNDARIO': { label: 'Endereço Splitter Secundário', value: (data) => `${data.ENDERECO_SPLITTER_SECUNDARIO.streetName}, ${data.ENDERECO_SPLITTER_SECUNDARIO.streetNr}` },
};

const acessoOltLabelDisplay: Record<string, InputCustom> = {
  FUSION_NETWORK: { label: 'Rede Fusion' },
  TIPO_EQUIPAMENTO_OLT: { label: 'Tipo de Equipamento' },
  VENDOR_OLT: { label: 'Vendor' },
  MODEL_OLT: { label: 'Modelo' },
  HOSTNAME_OLT: { label: 'Hostname' },
  RACK_OLT: { label: 'Rack' },
  SLOT_EQUIPAMENTO: { label: 'Slot' },
  PLACA_ID: { label: 'Placa ID' },
  ID_GPON: { label: 'ID GPON' },
  IP_OLT: { label: 'IP de Gerência' },
  ONT_SERIAL_NUMBER: { label: 'SerialNumber OLT' },
};

const acessoVlanLabelDisplay: Record<string, InputCustom> = {
  VLAN_CUSTOMER: { label: 'Cliente VLAN' },
  VLAN_NETWORK: { label: 'Rede VLAN' },
  SLID: { label: 'SLID' },
};

const acessoGeralLabelDisplay: Record<string, InputCustom> = {
  TELEPHONIC_AREA_OLT: { label: 'Area Telefônica' },
  LOCATION_OLT: { label: 'Localização OLT' },
  A_CLLI: { label: 'CLLI' },
  A_LOCATION_TYPE: { label: 'Tipo de localização' },
  CNL_OLT: { label: 'CNL' },
};

const equipClientLabelDisplay: Record<string, InputCustom> = {
  HOSTNAME_ROTEADOR_CLIENTE: { label: 'Hostname' },
  MODELO_ROTEADOR_CLIENTE: { label: 'Modelo' },
  VENDOR_ROTEADOR_CLIENTE: { label: 'Vendor' },
  TIPO_CONFIGURACAO: { label: 'Tipo de Configuração' },
  TIPO_ROTEADOR_CLIENTE: { label: 'Tipo de Router' },
  LOGIN_TECNICO_CONFIGURACAO: { label: 'Login' },
};

const dadosLabelDisplay: Record<string, InputCustom> = {
  VLAN_NETWORK: { label: 'Rede VLAN' },
  VLAN_CUSTOMER: { label: 'Cliente VLAN' },
  HOSTNAME_ROTEADOR_VIVO_PRINCIPAL: { label: 'Hostname Router Vivo' },
  INTERFACE_FULL_ROTEADOR_VIVO_PRINCIPAL: { label: 'Interface Full Router Vivo' },
  INTERFACE_ROTEADOR_VIVO_PRINCIPAL: { label: 'Interface Router Vivo' },
  TIPO_INTERFACE_ROTEADOR_VIVO_PRINCIPAL: { label: 'Tipo Interface Router Vivo' },
  VENDOR_ROTEADOR_VIVO_PRINCIPAL: { label: 'Fabricante Router Vivo' },
  MODELO_ROTEADOR_VIVO_PRINCIPAL: { label: 'Modelo Router Vivo' },
  TAG_ROTEADOR_VIVO_PRINCIPAL: { label: 'TAG Router Vivo' },
  TIPO_ROTEADOR_VIVO_PRINCIPAL: { label: 'Tipo Router Vivo' },
  HOSTNAME_ROTEADOR_VIVO_BACKUP: { label: 'Hostname Router Backup' },
  INTERFACE_FULL_ROTEADOR_VIVO_BACKUP: { label: 'Interface Full Router Backup' },
  INTERFACE_ROTEADOR_VIVO_BACKUP: { label: 'Interface Router Backup' },
  TIPO_INTERFACE_ROTEADOR_VIVO_BACKUP: { label: 'Tipo Interface Router Backup' },
  VENDOR_ROTEADOR_VIVO_BACKUP: { label: 'Fabricante Router Backup' },
  MODELO_ROTEADOR_VIVO_BACKUP: { label: 'Modelo Router Backup' },
  TAG_ROTEADOR_VIVO_BACKUP: { label: 'TAG Router Backup' },
  TIPO_ROTEADOR_VIVO_BACKUP: { label: 'Tipo Router Backup' },
};

const dadosVlanLabelDisplay: Record<string, InputCustom> = {
  FUSION_NETWORK: { label: 'Rede Fusion' },
  VLAN_CUSTOMER: { label: 'Cliente VLAN' },
  VLAN_NETWORK: { label: 'Rede VLAN' },
  Z_CLLI: { label: 'CLLI' },
  Z_LOCATION_TYPE: { label: 'Tipo de Localização' },
};

const dadosEquipLabelDisplay: Record<string, InputCustom> = {
  HOSTNAME_ROTEADOR_VIVO_PRINCIPAL: { label: 'Hostname' },
  MODELO_ROTEADOR_VIVO_PRINCIPAL: { label: 'Modelo' },
  VENDOR_ROTEADOR_VIVO_PRINCIPAL: { label: 'Vendor' },
  TIPO_ROTEADOR_VIVO_PRINCIPAL: { label: 'Tipo' },
  TIPO_INTERFACE_ROTEADOR_VIVO_PRINCIPAL: { label: 'Tipo de Interface' },
  INTERFACE_FULL_ROTEADOR_VIVO_PRINCIPAL: { label: 'Interface Full Router' },
  INTERFACE_ROTEADOR_VIVO_PRINCIPAL: { label: 'Interface Router' },
  TAG_ROTEADOR_VIVO_PRINCIPAL: { label: 'TAG Router' },
};

const dadosEquipBkpLabelDisplay: Record<string, InputCustom> = {
  HOSTNAME_ROTEADOR_VIVO_BACKUP: { label: 'Hostname' },
  MODELO_ROTEADOR_VIVO_BACKUP: { label: 'Modelo' },
  VENDOR_ROTEADOR_VIVO_BACKUP: { label: 'Vendor' },
  TIPO_ROTEADOR_VIVO_BACKUP: { label: 'Tipo' },
  INTERFACE_FULL_ROTEADOR_VIVO_BACKUP: { label: 'Interface Full' },
  INTERFACE_ROTEADOR_VIVO_BACKUP: { label: 'Interface Router' },
  TAG_ROTEADOR_VIVO_BACKUP: { label: 'Tag Router' },
};

const transmissaoHl3LabelDisplay: Record<string, InputCustom> = {
  HIERACHY_LEVEL_THREE: { label: 'Hierarchy Level' },
  ANEL_METRO_NAME_HL3: { label: 'Nome' },
  ANEL_METRO_INTERFACE_FULL_PRINCIPAL_HL3: { label: 'Interface Full Principal' },
  ANEL_METRO_INTERFACE_PRINCIPAL_HL3: { label: 'Interface Principal' },
  ANEL_METRO_INTERFACE_FULL_BACKUP_HL3: { label: 'Interface Full Backup' },
  ANEL_METRO_INTERFACE_BACKUP_HL3: { label: 'Interface Backup' },
};

const transmissaoHl4LabelDisplay: Record<string, InputCustom> = {
  HIERACHY_LEVEL_FOUR: { label: 'Hierarchy Level' },
  ANEL_METRO_NAME_HL4: { label: 'Nome' },
  ANEL_METRO_INTERFACE_FULL_PRINCIPAL_HL4: { label: 'Interface Full Principal' },
  ANEL_METRO_INTERFACE_PRINCIPAL_HL4: { label: 'Interface Principal' },
};

const transmissaoAgregadorLabelDisplay: Record<string, InputCustom> = {
  ROTEADOR_AGREGADOR: { label: 'Router Agregador' },
  ANEL_METRO_NAME_RAGG: { label: 'Nome RAGG' },
  ANEL_METRO_INTERFACE_FULL_PRINCIPAL_RAGG: { label: 'Interface Full Principal' },
  ANEL_METRO_INTERFACE_PRINCIPAL_RAGG: { label: 'Interface Principal' },
};

const transmissaoSwitchConcLabelDisplay: Record<string, InputCustom> = {
  SWITCH_CONCENTRACAO: { label: 'Switch Agregação' },
  ANEL_METRO_NAME_SWC: { label: 'Nome' },
  ANEL_METRO_INTERFACE_FULL_PRINCIPAL_SWC: { label: 'Interface Full Principal' },
  ANEL_METRO_INTERFACE_PRINCIPAL_SWC: { label: 'Interface Principal' },
  ANEL_METRO_INTERFACE_FULL_BACKUP_SWC: { label: 'Interface Full Backup' },
  ANEL_METRO_INTERFACE_BACKUP_SWC: { label: 'Interface Backup' },
};

const transmissaoSwitchDistLabelDisplay: Record<string, InputCustom> = {
  SWITCH_DISTRIBUCAO: { label: 'Switch Distribuição' },
  ANEL_METRO_NAME_SWD: { label: 'Nome' },
  ANEL_METRO_INTERFACE_FULL_PRINCIPAL_SWD: { label: 'Interface Full Principal' },
  ANEL_METRO_INTERFACE_PRINCIPAL_SWD: { label: 'Interface Principal' },
};

// Sub Groups Mapping

const geralSubGroups: SubGroup[] = [
  {
    title: 'Informações do Cliente e Serviço',
    fields: geralLabelDisplay,
  },
  {
    title: 'Histórico',
    customFields: true,
  }
];

const ipsSubGroups: SubGroup[] = [
  {
    title: 'IPs IPV4 LAN CPE',
    fields: ipsIpv4LanCpeDisplay,
  },
  {
    title: 'IPs IPV6 LAN CPE',
    fields: ipsIpv6LanCpeDisplay,
  },
  {
    title: 'IPs IPV4 WAN CPE',
    fields: ipsIpv4WanCpeLabelDisplay,
  },
  {
    title: 'IPs IPV6 WAN CPE',
    fields: ipsIpv6WanCpeLabelDisplay,
  },
  {
    title: 'Loopback',
    fields: ipsLoopbackLabelDisplay,
  },
];

const acessoSubGroups: SubGroup[] = [
  {
    title: 'Equipamento OLT',
    fields: acessoOltLabelDisplay,
  },
  {
    title: 'VLAN',
    fields: acessoVlanLabelDisplay,
  },
  {
    title: 'Geral',
    fields: acessoGeralLabelDisplay,
  },
];

const dadosSubGroups: SubGroup[] = [
  {
    title: 'VLAN',
    fields: dadosVlanLabelDisplay,
  },
  {
    title: 'Equipamento Principal',
    fields: dadosEquipLabelDisplay,
  },
  {
    title: 'Equipamento Backup',
    fields: dadosEquipBkpLabelDisplay,
  },
];

const transmissaoSubGroups: SubGroup[] = [
  {
    title: 'HL3',
    fields: transmissaoHl3LabelDisplay,
  },
  {
    title: 'HL4',
    fields: transmissaoHl4LabelDisplay,
  },
  {
    title: 'AGREGADOR',
    fields: transmissaoAgregadorLabelDisplay,
  },
  {
    title: 'SWITCH CONCENTRAÇÃO',
    fields: transmissaoSwitchConcLabelDisplay,
  },
  {
    title: 'SWITCH DISTRIBUIÇÃO',
    fields: transmissaoSwitchDistLabelDisplay,
  },
];

//  Sub Tabs Mapping
const topologiaTabsOrder = [
  { key: 'GERAL', label: 'GERAL' },
  { key: 'IPS', label: 'IPS ROUTER CLIENTE' },
  { key: 'PLANTA EXTERNA', label: 'PLANTA EXTERNA' },
  { key: 'ACESSO', label: 'INFORMAÇÕES DE OLT' },
  { key: 'ERB.ACESSO', label: 'INFORMAÇÕES ERB' },
  { key: 'DADOS', label: 'EQUIPAMENTO CORE VIVO' },
  { key: 'EQUIPAMENTO CLIENTE', label: 'EQUIPAMENTO CLIENTE' },
  { key: 'ERB.SWT', label: 'ERB SWT' },
  { key: 'TRANSMISSAO', label: 'TRANSMISSÃO' },
  { key: 'ERB.TRANSMISSAO', label: 'ERB TRANSMISSÃO' },
];

// TabPanel Functions
function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component='div'>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

// CircuitRework steps
const circuitReworkSteps = ['Desconfiguração', 'Desalocação de IPs', 'Alocação de IPs', 'Configuração'];

const IpInternetDisplay = ({ facilityInfo }: Props) => {
  const [activeTabId, setActiveTabId] = useState<number>(1);
  const [activeTopologySubTab, setActiveTopologySubTab] = useState<number>(0);
  const [tracking, setTracking] = useState<HpsdTracking | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingTaskTracking, setLoadingTaskTracking] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [warning, setWarning] = useState<string>();
  const [errorTaskTracking, setErrorTaskTracking] = useState<string>();
  const [warningTaskTracking, setWarningTaskTracking] = useState("");
  const [success, setSuccess] = useState("");
  const [vendorList, setVendorList] = useState<Vendor[]>([]);
  const [selectedVendor, setSelectVendor] = useState<Vendor | null>(null);
  const [selectedModel, setSelectModel] = useState<Model | null>(null);
  const [isManualConfig, setIsManualConfig] = useState(false);
  const [manualConfig, setManualConfig] = useState<ManualConfig>({ log: '', justify: '' });
  const [loginTecnico, setLoginTecnico] = useState<string>('');
  const [hostname, setHostname] = useState<string>('');
  // const [autoConfig, setAutoConfig] = useState<AutoConfig>({ loginTecnico: '', hostname: '' });
  const [hpsdActionResultId, setHpsdActionResultId] = useState<string>();
  const [openVendor, setOpenVendor] = React.useState(false);
  const loadingVendor = openVendor && vendorList.length === 0;
  const [hpsdConfigurationCallback, setHpsdConfigurationCallback] = useState<HpsdConfigCallback>();
  const [hpsdCertificationCallback, setHpsdCertificationCallback] = useState<HpsdConfigCallback>();
  const [openFinishTaskSnackbar, setOpenFinishTaskSnackbar] = useState(false);
  const [loginOrder, setLoginOrder] = useState<Order>();
  const [circuitReworkActiveSteps, setCircuitReworkActiveSteps] = useState(circuitReworkSteps);
  const [circuitReworkStepResponse, setCircuitReworkStepResponse] = useState<HpsdConfigCallback>();
  const [circuitReworkLoading, setCircuitReworkLoading] = useState(false);
  const [circuitReworkNetworkAction, setCircuitReworkNetworkAction] = useState(true);
  const [circuitReworkRequestIPs, setCircuitReworkRequestIPs] = useState(false);
  const [circuitReworkActiveStepCount, setCircuitReworkActiveStepCount] = useState(0);
  const [circuitReworkLastTask, setCircuitReworkLastTask] = useState<Task>();
  const [diagnosticCallback, setDiagnosticCallback] = useState<HpsdConfigCallback>();
  const [serviceGeralData, setServiceGeralData] = useState<Value>(facilityInfo.serviceCharacteristic.find((service) => service.name === "GERAL")!.value);
  const [serviceIpData, setServiceIpData] = useState<Value>(facilityInfo.serviceCharacteristic.find((service) => service.name === "IPS")!.value);
  const [topologiaTabs, setTopologiaTabs] = useState<TopologiaTabsInfo[]>(topologiaTabsOrder);

  const { userHasRole } = useUserContext();

  // Main Tabs 
  const mainTabs: TabsInfo[] = [
    { id: MainTabs['Informações de Topologia'], label: 'Informações de Topologia' },
    { id: MainTabs.Configuração, label: 'Configuração', disabled: true },
    { id: MainTabs.Diagnóstico, label: 'Diagnóstico' },
    { id: MainTabs['DoneCertification'], label: 'Flag Done Certification', disabled: !userHasRole([Roles.admin, Roles.avancado]) },
    { id: MainTabs['CircuitRework'], label: 'Reconfigurar Circuito' },
  ];


  /**
   * Search Task Tracking and update topologia tabs automatically for every change on designadorId 
   */
  useEffect(() => {
    searchTaskTracking(facilityInfo.id);
    let servicesName = facilityInfo.serviceCharacteristic.map((service) => (service.name));
    setTopologiaTabs(topologiaTabsOrder.filter(tab => servicesName.includes(tab.key)));
    console.log('Update Tabs');
  }, [facilityInfo]);

  /**Get the Vendors and Models list to select in the configuration */
  useEffect(() => {
    const getVendorsAndModels = async () => {
      const resp = await filesInfoRequest();
      let listVendors = resp.b2b.devices.router.vendor.map((vendor) => {
        let models = vendor.models.map((model) => { return { name: model.name }; });

        return { name: vendor.name, models: models };
      });

      setVendorList(listVendors);
    };

    if (openVendor && vendorList.length === 0) {
      getVendorsAndModels();
    }
  }, [openVendor]);

  //Method to ping the Hpsd Config Callback
  const pingCallbackStatus = async (hpsdActionResultId: string, intervalId?: NodeJS.Timer) => {
    try {
      const resp = await hpsdConfigCallbackRequest(hpsdActionResultId);
      if (resp.data && Object.keys(resp.data).length > 0) {
        // If the callback is finished, clear the interval
        clearInterval(intervalId);
        setHpsdActionResultId(undefined);
        if (resp.data.event.serviceOrder.serviceOrderItem) {
          const service = resp.data.event.serviceOrder.serviceOrderItem[0].service;
          const state = resp.data.event.serviceOrder.state;
          //ConfigurationCPE action
          if (service.category === 'ConfiguracaoCPE') {
            setLoading(false);
            if (state === "completed") {
              setSuccess('Configuração realizada com sucesso.');
              setHpsdConfigurationCallback(resp.data);
            } else {
              setError(`Falha na configuração: \n${resp.data.event.serviceOrder.description ?? 'Entrar em contato com o HPSD.'}`);
            }
            //Show button and snackbar to complete the task
            setOpenFinishTaskSnackbar(true);
          } // CertificationCPE action 
          else if (service.category === 'CertificacaoCPE') {
            setLoading(false);
            if (state === "completed") {
              setSuccess('Certificação realizada com sucesso.');
              setHpsdCertificationCallback(resp.data);
            } else {
              setError(`Falha na certificação: \n${resp.data.event.serviceOrder.description ?? 'Entrar em contato com o HPSD.'}`);
            }
            // Update the task status  to completed
            finishTask(resp.data);
          } // Diagnostico action
          else if (service.category === 'Diagnostico') {
            setLoading(false);
            if (state === "completed") {
              setSuccess('Diagnóstico realizado com sucesso.');
              setDiagnosticCallback(resp.data);
            } else {
              setError(`Falha no diagnóstico: \n${resp.data.event.serviceOrder.description ?? 'Entrar em contato com o HPSD.'}`);
              setDiagnosticCallback(undefined);
            }
          }
        } else {
          setError('Timeout - não recebeu resposta do callback HPSD.');
        }
      }
    } catch (error) {
      handleError({ error, setError });
      clearInterval(intervalId);
      setLoading(false);
    }
  };

  /**
 * Request callback response from the action
 */
  useEffect(() => {
    if (hpsdActionResultId) {
      // Set up a periodic ping every 5 seconds to check if the Config Task is already finished and get the result
      const intervalId = setInterval(async () => {
        // Check task status initially
        pingCallbackStatus(hpsdActionResultId, intervalId);
      }, 5000);

      // Clear the interval when the component is unmounted
      return () => clearInterval(intervalId);
    }
  }, [hpsdActionResultId]);

  /**
   * Search for circuit rework task everytime it's change to tab Redesenho de Circuito
   */
  useEffect(() => {
    if (activeTabId === MainTabs['CircuitRework']) {
      getHpsdCircuitReworkTask(facilityInfo.id);
    }
  }, [activeTabId, circuitReworkStepResponse]);

  const clearMsgs = () => {
    setSuccess('');
    setError('');
    setWarning('');
  };

  /** Get the latest Task progress of this designadorId */
  const searchTaskTracking = async (designadorId: string) => {
    try {
      setErrorTaskTracking("");
      setLoadingTaskTracking(true);
      const resp = await trackingHpsdListRequest(designadorId);
      if (resp.data && resp.data.length > 0) {
        setTracking(resp.data[0]);
        setLoginTecnico(resp.data[0].username);
      } else {
        setWarningTaskTracking("Nenhuma task encontrada");
      }
    } catch (error) {
      handleError({ error, setError: setErrorTaskTracking });
    } finally {
      setLoadingTaskTracking(false);
    }
  };

  const handleManualConfigTextFieldChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, fieldName: keyof ManualConfig) => {
    setManualConfig({
      ...manualConfig,
      [fieldName]: event.target.value,
    });
  };

  /**Automatic Configuration Action */
  const handleAutoConfigButton = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      setError('');
      setSuccess('');
      setLoading(true);

      const respOrder = (await orderLoginRequest(loginTecnico)).data;
      if (respOrder) {
        setLoginOrder(respOrder);
        const vendorName = selectedVendor!.name;
        const modelName = selectedModel!.name;

        const body: HpsdConfigBodyRequest = {
          service: {
            id: respOrder.specifications.XA_ACCESS_DESIGNATOR,
            category: 'ConfiguracaoCPE',
            // serviceOrderItem: 'Internet',
            serviceSpecification: 'Internet',
            CommercialOrderId: respOrder.customerOrderId
          },
          fields: [
            { name: 'NaturezaOrdem', value: 'Alta' },
            { name: 'VENDOR', value: vendorName },
            { name: 'MODELO', value: modelName },
            { name: 'HOSTNAME', value: hostname },
            { name: 'TIPO_CONFIGURACAO', value: 'AUTOMATICO' },
            { name: 'LOGIN_TECNICO_CONFIGURACAO', value: loginTecnico }
          ]
        };
        const respConfig = await hpsdConfigRequest(body);
        setHpsdActionResultId(respConfig.data?.id_result);
      }
    } catch (error) {
      handleError({ error, setError });
      setLoading(false);
    }
  };

  /**Manual Configuration Action */
  const handleManualConfigButton = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      setError('');
      setSuccess('');
      setLoading(true);

      const result: HpsdTaskResult = {
        designador: facilityInfo.id,
        messageResult: manualConfig.justify,
        results: [
          {
            completeLogs: manualConfig.log,
            idStatusResult: 1,
            headerDescription: "Configuração Manual",
            startDate: new Date().toISOString(),
            messageResult: '',
            networkCommandResults: [{
              idStatus: 1,
              log: manualConfig.log,
              procedureDescription: 'Configurado manualmente',
              result: "Configurado com sucesso",
              step: 1
            }]
          }
        ]
      };

      const task: HpsdTask = {
        taskType: "config",
        description: "Configuração de designador Manual",
        status: "COMPLETED",
        result: result,
      };

      const body: HpsdTaskTrackingUpdate = {
        designador: facilityInfo.id,
        product: 'INTERNET',
        status: "IN PROGRESS",
        tasks: [task]
      };
      const resp = await trackingHpsdUpdateRequest(body);
    } catch (error) {
      handleError({ error, setError });
    } finally {
      setLoading(false);
    }
  };

  /** Diagnostic Action */
  const handleDiagnosticButton = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      clearMsgs();
      setLoading(true);

      const body: HpsdConfigBodyRequest = {
        service: {
          id: facilityInfo.id,
          category: 'Diagnostico',
          serviceSpecification: 'Internet',
        },
        fields: [
          { name: 'NaturezaOrdem', value: 'Diagnostico' },
          { name: 'ELEMENTOS_DE_REDE', value: ["ALL"] },
          { name: 'FORCE_EXECUTE', value: 'SIM' }
        ]
      };
      const respDiag = await hpsdConfigRequest(body);
      setHpsdActionResultId(respDiag.data?.id_result);

    } catch (error) {
      handleError({ error, setError });
      setLoading(false);
    }
  };

  /** Done Certification Action */
  const handleDoneCertificationButton = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      clearMsgs();
      setLoading(true);

      const respOrder = (await orderLoginRequest(loginTecnico)).data;
      if (respOrder) {
        await doneCertificationRequest(respOrder.activityId);
        setSuccess('Done Certification realizado com sucesso.');
      } else {
        setWarning('Nenhuma ordem encontrada para o técnico informado.');
      }
    } catch (error) {
      handleError({ error, setError });
    } finally {
      setLoading(false);
    }
  };


  const finishTask = async (data: HpsdConfigCallback) => {
    try {
      const body: HpsdTaskTrackingUpdate = {
        designador: data.event.serviceOrder.serviceOrderItem![0].id,
        product: 'INTERNET',
        status: 'COMPLETED',
        tasks: data
      };
      await trackingHpsdUpdateRequest(body);
    } catch (error) {
      handleError({ error, setError });
    }
  };

  /**
   * Prepare the variables to execute the CircuitRework process
   */
  const handleCircuitRework = async () => {
    setError('');
    setCircuitReworkLoading(true);
    setCircuitReworkActiveStepCount(0);

    let updatedSteps = updateStepsCircuitRework();

    await executeCircuitReworkFlow(updatedSteps);
  };

  /**
   * Check and update which steps the Circuit Rework flow will execute
   * @returns Updated steps of the Circuit Rework Flow
   */
  const updateStepsCircuitRework: () => string[] = () => {
    let updatedSteps;
    /** If circuitReworkRequestIPs is true, keep all the steps. Otherwise remove the 'Desalocação de IPs' and 'Alocação de IPs' steps  */
    if (circuitReworkRequestIPs) {
      updatedSteps = circuitReworkSteps;
    } else {
      updatedSteps = circuitReworkSteps.filter(step => (step !== 'Desalocação de IPs') && (step !== 'Alocação de IPs'));
    }

    /** Check the state of the last task of CircuitRework to manage the starting step*/
    if (circuitReworkLastTask) {
      let taskType = circuitReworkLastTask.taskType;
      let status = circuitReworkLastTask.status;

      if (taskType === 'Desconexao' && status === 'COMPLETED') {
        updatedSteps = updatedSteps.filter((step) => step !== 'Desconfiguração');
      }
      if (taskType === 'Desalocacao' && status === 'FAILED') {
        updatedSteps = updatedSteps.filter((step) => (step !== 'Desconfiguração'));
      }
      if (taskType === 'Desalocacao' && status === 'COMPLETED') {
        updatedSteps = updatedSteps.filter((step) => (step !== 'Desconfiguração') && (step !== 'Desalocação de IPs'));
      }
      if (taskType === 'Alocacao' && status === 'FAILED') {
        updatedSteps = updatedSteps.filter((step) => (step !== 'Desconfiguração') && (step !== 'Desalocação de IPs'));
      }
      if (taskType === 'Alocacao' && status === 'COMPLETED') {
        updatedSteps = updatedSteps.filter((step) => (step !== 'Desconfiguração') && (step !== 'Desalocação de IPs') && (step !== 'Alocação de IPs'));
      }
      if (taskType === 'Configuracao' && status === 'FAILED') {
        updatedSteps = updatedSteps.filter((step) => (step !== 'Desconfiguração') && (step !== 'Desalocação de IPs') && (step !== 'Alocação de IPs'));
      }
    }

    return updatedSteps;
  };

  /**
   * Ping the HPSD Callback API until the response data is returned and is not a empty object
   * @param resultId The id of the async HPSD API request
   */
  const awaitCircuitReworkCompletion = async (resultId: string) => {
    try {
      let data: (HpsdConfigCallback | {}) = {};
      while (Object.keys(data).length === 0) {
        data = (await hpsdRedesenhoResultRequest(resultId)).data!;
        if (Object.keys(data).length === 0) {
          await new Promise(resolve => setTimeout(resolve, 5000)); // Wait 5 seconds to make the request again
        }
      }

      setCircuitReworkStepResponse(data as HpsdConfigCallback);
      if (Object.keys(data).length > 0 && "event" in data && data.event.serviceOrder.state === 'pending') {
        throw new Error(`Status do HPSD está em pending, entrar em contato com o HPSD${data.event.serviceOrder.description ? ` - ${data.event.serviceOrder.description}` : '.'}`);
      }
      if (Object.keys(data).length > 0 && "event" in data && data.event.serviceOrder.state === 'held') {
        throw new Error(`Status do HPSD retornou em held, entrar em contato com o HPSD${data.event.serviceOrder.description ? ` - ${data.event.serviceOrder.description}` : '.'}`);
      }
      if (Object.keys(data).length > 0 && "event" in data && data.event.serviceOrder.state === 'failed') {
        throw new Error(`Status do HPSD retornou com falha${data.event.serviceOrder.description ? ` - ${data.event.serviceOrder.description}` : '.'}`);
      }
    } catch (error) {
      throw error;
    }
  };

  /**
   * Get the facility info backup of the especified Designador ID
   * @param designador 
   * @returns 
   */
  const getFacilityBackup = async (designador: string) => {
    try {
      const facilityBkp = await hpsdRedesenhoBackupRequest(designador);
      return facilityBkp.data?.facility;
    } catch (error) {
      handleError({ error, setError });
    }
  };

  const getHpsdCircuitReworkTask = async (designador: string) => {
    try {
      setWarning('');
      const resp = await hpsdRedesenhoTaskRequest(designador);
      if (resp.data) setCircuitReworkLastTask(resp.data);
    } catch (error) {
      handleError({ error, setError: setWarning });
    }
  };

  /**
   * Execute the Circuit Reworking flow, making one request at time until all request bodies are called
   */
  const executeCircuitReworkFlow = async (steps: string[]) => {
    setCircuitReworkActiveSteps(steps);
    let stepBodiesRequest: HpsdConfigBodyRequest[] = [];
    //Get facility data backup
    const facilityBkp = await getFacilityBackup(facilityInfo.id);
    let serviceIpDataBkp: Value;
    let serviceGeralDataBkp: Value;
    if (facilityBkp) {
      serviceIpDataBkp = facilityBkp.serviceCharacteristic.find((service) => service.name === "IPS")!.value;
      serviceGeralDataBkp = facilityBkp.serviceCharacteristic.find((service) => service.name === "GERAL")!.value;
    }

    // build related_party_fields 
    const related_party_fields: Field[] = [
      { name: 'TipoCliente', value: 'B2B' }
    ];

    // Prepare the request body for each step
    steps.map((step) => {
      if (step === 'Desconfiguração') {
        stepBodiesRequest = [...stepBodiesRequest, {
          service: {
            id: facilityInfo.id,
            category: 'Desconexao',
            serviceSpecification: 'Internet',
          },
          fields: [
            { name: 'NaturezaOrdem', value: 'Alta' },
            { name: 'NETWORK_ACTION', value: circuitReworkNetworkAction.toString() },
          ],
          facility: facilityInfo,
          related_party_fields: related_party_fields
        }];
      }
      if (step === 'Desalocação de IPs') {
        stepBodiesRequest = [...stepBodiesRequest, {
          service: {
            id: facilityInfo.id,
            category: 'Desalocacao',
            serviceSpecification: 'IP',
          },
          fields: [
            { name: 'NaturezaOrdem', value: 'Alta' },
            { name: 'TipoIP', value: 'DINAMICO' },
            { name: 'BlocoIPV4', value: serviceIpDataBkp ? (serviceIpDataBkp['b2bLanNetworkAddress.ipv4.mascara'] ?? '') : (serviceIpData['b2bLanNetworkAddress.ipv4.mascara'] ?? '') },
            { name: 'BlocoIPV6', value: serviceIpDataBkp ? (serviceIpDataBkp!['b2bLanNetworkAddress.ipv6.mascara'] ?? '') : (serviceIpData!['b2bLanNetworkAddress.ipv6.mascara'] ?? '') },
            { name: 'TipoVelocidadeDown', value: serviceGeralDataBkp ? (serviceGeralDataBkp.UNIDADE_VELOCIDADE_DOWN ?? '') : (serviceGeralData.UNIDADE_VELOCIDADE_DOWN ?? '') },
            { name: 'TipoVelocidadeUp', value: serviceGeralDataBkp ? (serviceGeralDataBkp.UNIDADE_VELOCIDADE_UP ?? '') : (serviceGeralData.UNIDADE_VELOCIDADE_UP ?? '') },
            { name: 'VelocidadeDown', value: serviceGeralDataBkp ? (serviceGeralDataBkp.VELOCIDADE_DOWN ?? '') : (serviceGeralData.VELOCIDADE_DOWN ?? '') },
            { name: 'VelocidadeUp', value: serviceGeralDataBkp ? (serviceGeralDataBkp.VELOCIDADE_UP ?? '') : (serviceGeralData.VELOCIDADE_UP ?? '') },
            { name: 'NETWORK_ACTION', value: circuitReworkNetworkAction.toString() },
          ],
          facility: facilityInfo,
          related_party_fields: related_party_fields
        }];
      }
      if (step === 'Alocação de IPs') {
        stepBodiesRequest = [...stepBodiesRequest, {
          service: {
            id: facilityInfo.id,
            category: 'Alocacao',
            serviceSpecification: 'IP',
          },
          fields: [
            { name: 'NaturezaOrdem', value: 'Alta' },
            { name: 'TipoIP', value: 'DINAMICO' },
            { name: 'BlocoIPV4', value: serviceIpDataBkp ? (serviceIpDataBkp['b2bLanNetworkAddress.ipv4.mascara'] ?? '') : (serviceIpData['b2bLanNetworkAddress.ipv4.mascara'] ?? '') },
            { name: 'BlocoIPV6', value: serviceIpDataBkp ? (serviceIpDataBkp!['b2bLanNetworkAddress.ipv6.mascara'] ?? '') : (serviceIpData!['b2bLanNetworkAddress.ipv6.mascara'] ?? '') },
            { name: 'TipoVelocidadeDown', value: serviceGeralDataBkp ? (serviceGeralDataBkp.UNIDADE_VELOCIDADE_DOWN ?? '') : (serviceGeralData.UNIDADE_VELOCIDADE_DOWN ?? '') },
            { name: 'TipoVelocidadeUp', value: serviceGeralDataBkp ? (serviceGeralDataBkp.UNIDADE_VELOCIDADE_UP ?? '') : (serviceGeralData.UNIDADE_VELOCIDADE_UP ?? '') },
            { name: 'VelocidadeDown', value: serviceGeralDataBkp ? (serviceGeralDataBkp.VELOCIDADE_DOWN ?? '') : (serviceGeralData.VELOCIDADE_DOWN ?? '') },
            { name: 'VelocidadeUp', value: serviceGeralDataBkp ? (serviceGeralDataBkp.VELOCIDADE_UP ?? '') : (serviceGeralData.VELOCIDADE_UP ?? '') },
            { name: 'NETWORK_ACTION', value: circuitReworkNetworkAction.toString() },
          ],
          related_party_fields: related_party_fields
        }];
      }
      if (step === 'Configuração') {
        stepBodiesRequest = [...stepBodiesRequest, {

          service: {
            id: facilityInfo.id,
            category: 'Configuracao',
            serviceSpecification: 'Internet',
          },
          fields: [
            { name: 'NaturezaOrdem', value: 'Alta' },
            { name: 'TipoIP', value: 'DINAMICO' },
            { name: 'BlocoIPV4', value: serviceIpDataBkp ? (serviceIpDataBkp['b2bLanNetworkAddress.ipv4.mascara'] ?? '') : (serviceIpData['b2bLanNetworkAddress.ipv4.mascara'] ?? '') },
            { name: 'BlocoIPV6', value: serviceIpDataBkp ? (serviceIpDataBkp!['b2bLanNetworkAddress.ipv6.mascara'] ?? '') : (serviceIpData!['b2bLanNetworkAddress.ipv6.mascara'] ?? '') },
            { name: 'TipoVelocidadeDown', value: serviceGeralDataBkp ? (serviceGeralDataBkp.UNIDADE_VELOCIDADE_DOWN ?? '') : (serviceGeralData.UNIDADE_VELOCIDADE_DOWN ?? '') },
            { name: 'TipoVelocidadeUp', value: serviceGeralDataBkp ? (serviceGeralDataBkp.UNIDADE_VELOCIDADE_UP ?? '') : (serviceGeralData.UNIDADE_VELOCIDADE_UP ?? '') },
            { name: 'VelocidadeDown', value: serviceGeralDataBkp ? (serviceGeralDataBkp.VELOCIDADE_DOWN ?? '') : (serviceGeralData.VELOCIDADE_DOWN ?? '') },
            { name: 'VelocidadeUp', value: serviceGeralDataBkp ? (serviceGeralDataBkp.VELOCIDADE_UP ?? '') : (serviceGeralData.VELOCIDADE_UP ?? '') },
            { name: 'NETWORK_ACTION', value: circuitReworkNetworkAction.toString() },
          ],
          related_party_fields: related_party_fields
        }];
      }
    });

    try {
      for (let i = 0; i < stepBodiesRequest.length; i++) {
        const resp = await hpsdConfigRequest(stepBodiesRequest[i]);
        if (resp.data) {
          await awaitCircuitReworkCompletion(resp.data.id_result);
          setCircuitReworkActiveStepCount((prevActiveStep) => prevActiveStep + 1);
        }
      }
    } catch (error) {
      handleError({ error, setError });
    } finally {
      setCircuitReworkLoading(false);
    }
  };

  return (
    <Box>
      {/* Defining Tabs */}
      {mainTabs.map((tab) => {
        if (tab.label === 'Certificação') {
          tab.disabled = tab.disabled || !!hpsdConfigurationCallback;
        }
        return (
          <Button
            key={tab.id}
            variant={activeTabId === tab.id ? "contained" : "outlined"}
            className={`main_tab ${activeTabId === tab.id ? 'active' : ""}`}
            onClick={() => setActiveTabId(tab.id)}
            disabled={tab.disabled}
          >
            {tab.label}
          </Button>
        );
      })}
      <Box marginTop={2}>
        <Grid container spacing={2}>
          {/* Main Tabs */}
          <Grid item xs={9}>
            <Card sx={{ borderRadius: '20px' }}>
              <Stack sx={{ width: '100%' }} spacing={2}>
                {success && (
                  <Alert severity="success" variant='filled' onClose={() => { setSuccess(''); }}>
                    {success}
                  </Alert>
                )}
                {error && (
                  <Alert severity="error" variant='filled' onClose={() => { setError(''); }}>
                    {error}
                  </Alert>
                )}
                {warning && (
                  <Alert severity="warning" variant='filled' onClose={() => { setWarning(''); }}>
                    {warning}
                  </Alert>
                )}
              </Stack>

              {/* Tab Informações de Topologia */}
              {activeTabId === MainTabs['Informações de Topologia'] && (
                <>
                  {/* Sub Tabs da Topologia */}
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs
                      value={activeTopologySubTab}
                      onChange={(e, newTabValue) => { setActiveTopologySubTab(newTabValue); }}
                      variant="fullWidth"
                    >
                      {topologiaTabs.map((tab, index) => {
                        return <Tab key={index} label={tab.label} {...a11yProps(index)} />;
                      })}
                    </Tabs>
                  </Box>
                  <CardContent>
                    {facilityInfo.serviceCharacteristic.map((service, index) => (
                      <CustomTabPanel key={index} value={activeTopologySubTab} index={topologiaTabs.findIndex((tab) => tab.key === service.name)}>
                        <Box
                          sx={{
                            '& .MuiTextField-root': { m: 1 },
                          }}
                        >
                          {service.name === "GERAL" ? (
                            <>
                              {geralSubGroups.map((subGroup) => (
                                <FormGroups key={subGroup.title} title={subGroup.title}>
                                  {subGroup.fields && (
                                    <DynamicView data={service.value} labelMap={subGroup.fields} />
                                  )}
                                  {subGroup.customFields && (
                                    <TableContainer>
                                      <Table>
                                        <TableHead>
                                          <TableRow>
                                            <TableCell>Atividade</TableCell>
                                            <TableCell>Data</TableCell>
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          {service.value.HISTORICO_STATUS_CICLO_DE_VIDA?.map((row, index) => (
                                            <TableRow
                                              key={index}
                                            >
                                              <TableCell>{row[0]}</TableCell>
                                              <TableCell>{row[1]}</TableCell>
                                            </TableRow>
                                          ))}
                                        </TableBody>
                                      </Table>
                                    </TableContainer>
                                  )}
                                </FormGroups>
                              ))}
                            </>
                          ) : service.name === "PLANTA EXTERNA" ? (
                            <DynamicView data={service.value} labelMap={plantaExternaLabelDisplay} />
                          ) : service.name === "ACESSO" ? (
                            <>
                              {acessoSubGroups.map((subGroup) => (
                                <FormGroups key={subGroup.title} title={subGroup.title}>
                                  <DynamicView data={service.value} labelMap={subGroup.fields!} />
                                </FormGroups>
                              ))}
                            </>
                          ) : service.name === "EQUIPAMENTO CLIENTE" ? (
                            <DynamicView data={service.value} labelMap={equipClientLabelDisplay} />
                          ) : service.name === "IPS" ? (
                            <>
                              {ipsSubGroups.map((subGroup) => (
                                <FormGroups key={subGroup.title} title={subGroup.title}>
                                  <DynamicView data={service.value} labelMap={subGroup.fields!} />
                                </FormGroups>
                              ))}
                            </>
                          ) : service.name === "DADOS" ? (
                            <>
                              {dadosSubGroups.map((subGroup) => (
                                <FormGroups key={subGroup.title} title={subGroup.title}>
                                  <DynamicView data={service.value} labelMap={subGroup.fields!} />
                                </FormGroups>
                              ))}
                            </>
                          ) : service.name === "TRANSMISSAO" ? (
                            <>
                              {transmissaoSubGroups.map((subGroup) => (
                                <FormGroups key={subGroup.title} title={subGroup.title}>
                                  <DynamicView data={service.value} labelMap={subGroup.fields!} />
                                </FormGroups>
                              ))}
                            </>
                          ) : (
                            <RecursiveTextField data={service.value} />
                          )}
                        </Box>
                      </CustomTabPanel>
                    ))}
                  </CardContent>
                </>
              )}
              {/* Tab Configuração */}
              {activeTabId === MainTabs.Configuração && (
                <CardContent>
                  {!hpsdConfigurationCallback && (
                    <>
                      {isManualConfig ? (
                        <Box
                          display={'flex'}
                          justifyContent={'center'}
                          flexDirection={'column'}
                          component='form'
                          onSubmit={handleManualConfigButton}
                          sx={{
                            '& .MuiTextField-root': { m: 1 },
                          }}
                        >
                          <TextField
                            label="Justificativa"
                            value={manualConfig.justify}
                            onChange={(e) => handleManualConfigTextFieldChange(e, 'justify')}
                            required
                          />
                          <TextField
                            label="Log do Resultado"
                            multiline
                            rows={4}
                            value={manualConfig.log}
                            onChange={(e) => handleManualConfigTextFieldChange(e, 'log')}
                            required
                          />
                          <Button
                            className='button'
                            disabled={loading}
                            startIcon={<PlayCircleFilledIcon />}
                            type='submit'
                          >
                            {loading ? `Configurando...` : `Realizar Configuração Manual`}
                          </Button>
                        </Box>
                      ) : (
                        <Box
                          display={'flex'}
                          justifyContent={'center'}
                          flexDirection={'column'}
                          component='form'
                          onSubmit={handleAutoConfigButton}
                          sx={{
                            '& .MuiTextField-root': { m: 1 },
                          }}
                        >
                          <TextField
                            label="Login do técnico"
                            value={loginTecnico}
                            onChange={(e) => setLoginTecnico(e.target.value)}
                            required
                          />
                          <TextField
                            label="Hostname"
                            value={hostname}
                            onChange={(e) => setHostname(e.target.value)}
                            required
                          />
                          <Autocomplete
                            disablePortal
                            open={openVendor}
                            onOpen={() => {
                              setOpenVendor(true);
                            }}
                            onClose={() => {
                              setOpenVendor(false);
                            }}
                            options={vendorList}
                            isOptionEqualToValue={(option, value) => option.name === value.name}
                            getOptionLabel={(option) => option.name}
                            loading={loadingVendor}
                            value={selectedVendor}
                            onChange={(e, newValue) => {
                              setSelectVendor(newValue);
                              setSelectModel(null);
                            }}
                            sx={{ display: 'inherit' }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Fabricante"
                                required
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <>
                                      {loadingVendor ? <CircularProgress color="inherit" size={20} /> : null}
                                      {params.InputProps.endAdornment}
                                    </>
                                  )
                                }}
                              />
                            )}
                          />
                          <Autocomplete
                            disablePortal
                            disabled={!selectedVendor}
                            options={selectedVendor ? selectedVendor.models : []}
                            value={selectedModel}
                            onChange={(e, newValue) => {
                              setSelectModel(newValue);
                            }}
                            isOptionEqualToValue={(option, value) => option.name === value.name}
                            getOptionLabel={(option) => option.name}
                            sx={{ display: 'inherit' }}
                            renderInput={(params) => <TextField {...params} label="Modelo" required />}
                          />
                          <Button
                            className='button'
                            variant='contained'
                            sx={{ alignSelf: 'center' }}
                            disabled={loading}
                            startIcon={<PlayCircleFilledIcon />}
                            type='submit'
                          >
                            {loading ? `Configurando...` : `Requisitar Configuração Automática`}
                          </Button>
                        </Box>
                      )}
                    </>
                  )}
                  {hpsdConfigurationCallback && loginOrder && (
                    <>
                      <Box display={'flex'} justifyContent={'center'} marginTop={'1em'}>
                        <FenixFinishTaskSnackbar
                          open={openFinishTaskSnackbar}
                          setOpen={setOpenFinishTaskSnackbar}
                          product={'INTERNET'}
                          loginOrder={loginOrder}
                        />
                      </Box>
                    </>
                  )}
                </CardContent>
              )}
              {/** Tab de Diagnóstico */}
              {activeTabId === MainTabs.Diagnóstico && (
                <CardContent>
                  <Box
                    display={'flex'}
                    justifyContent={'center'}
                    flexDirection={'column'}
                    component='form'
                    onSubmit={handleDiagnosticButton}
                    sx={{
                      '& .MuiTextField-root': { m: 1 },
                    }}
                  >
                    <Button
                      className='button'
                      sx={{ alignSelf: 'center' }}
                      disabled={loading}
                      startIcon={<PlayCircleFilledIcon />}
                      type='submit'
                      variant='contained'
                    >
                      {loading ? `Diagnosticando...` : `Realizar Diagnóstico`}
                    </Button>
                  </Box>
                  <Box>
                    {diagnosticCallback && (
                      <HpsdResultDetail
                        service={diagnosticCallback.event.serviceOrder.serviceOrderItem![0].service}
                      />
                    )}
                  </Box>
                </CardContent>
              )}
              {/** Tab de Finalizar Certificação */}
              {activeTabId === MainTabs['DoneCertification'] && (
                <CardContent>
                  <Box
                    display={'flex'}
                    justifyContent={'center'}
                    flexDirection={'column'}
                    component='form'
                    onSubmit={handleDoneCertificationButton}
                    sx={{
                      '& .MuiTextField-root': { m: 1 },
                    }}
                  >
                    <TextField
                      label="Login do técnico"
                      value={loginTecnico}
                      onChange={(e) => setLoginTecnico(e.target.value)}
                      required
                    />
                    <Button
                      className='button'
                      sx={{ alignSelf: 'center' }}
                      disabled={loading}
                      startIcon={<PlayCircleFilledIcon />}
                      type='submit'
                      variant='contained'
                    >
                      {loading ? `Finalizando...` : `Finalizar Ordem`}
                    </Button>
                  </Box>
                </CardContent>
              )}
              {/* Tab Redesenho do Circuito */}
              {activeTabId === MainTabs['CircuitRework'] && (
                <CardContent>
                  <Box
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    flexDirection={'column'}
                    sx={{
                      '& .MuiTextField-root': { m: 1 },
                    }}
                  >
                    {circuitReworkLastTask && (

                      <TaskCircuitReworkDetailCard task={circuitReworkLastTask} />
                    )}
                    <Box display={'flex'} flexDirection={'column'}>
                      <ProtectedComponent roles={[Roles.avancado, Roles.admin]}>
                        <FormControlLabel
                          label='Aplica na Rede?'
                          control={
                            <Checkbox
                              checked={circuitReworkNetworkAction}
                              onChange={(e) => setCircuitReworkNetworkAction(e.target.checked)}
                            />
                          }
                        />
                      </ProtectedComponent>
                      <FormControlLabel
                        label='Solicita novos blocos de IPs?'
                        control={
                          <Checkbox
                            checked={circuitReworkRequestIPs}
                            onChange={(e) => setCircuitReworkRequestIPs(e.target.checked)}
                          />
                        }
                      />
                    </Box>
                    <Button
                      variant='contained'
                      className='button'
                      startIcon={<PlayCircleFilledIcon />}
                      onClick={() => handleCircuitRework()}
                      disabled={!!circuitReworkLastTask && circuitReworkLastTask?.status === 'IN PROGRESS'}
                    >
                      Reconfigurar
                    </Button>
                    {!!circuitReworkStepResponse && (
                      <Box sx={{ width: '100%' }} textAlign={'center'} marginTop={5}>
                        <Stepper activeStep={circuitReworkActiveStepCount}>
                          {circuitReworkActiveSteps.map((label, index) => {
                            return (
                              <Step key={label}>
                                <StepLabel error={circuitReworkActiveStepCount === index && !!error}>{label}</StepLabel>
                              </Step>
                            );
                          })}
                        </Stepper>
                        {circuitReworkActiveStepCount === circuitReworkActiveSteps.length && (
                          <Typography>
                            Todas as etapas foram completadas com sucesso.
                          </Typography>
                        )}
                      </Box>
                    )}
                    <Backdrop
                      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 100 }}
                      open={circuitReworkLoading}
                    >
                      <Box sx={{ width: '80%' }} textAlign={'center'}>
                        <Card>
                          <CardContent>
                            <CircularProgress color='inherit' />
                            <Stepper activeStep={circuitReworkActiveStepCount}>
                              {circuitReworkActiveSteps.map((label, index) => {
                                return (
                                  <Step key={label}>
                                    <StepLabel error={circuitReworkActiveStepCount === index && !!error}>{label}</StepLabel>
                                  </Step>
                                );
                              })}
                            </Stepper>
                          </CardContent>
                        </Card>
                      </Box>
                    </Backdrop>
                  </Box>
                </CardContent>
              )}
            </Card>
          </Grid>
          {/* Side bar info */}
          <Grid item xs={3}>
            <Card sx={{ borderRadius: '20px' }}>
              <CardContent>
                <div style={{ textAlign: 'center', padding: '0.5em' }}>
                  Informações do Designador
                </div>
                <Divider sx={{ m: 1 }} />
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  alignItems={'center'}
                  sx={{
                    '& .MuiTextField-root': { m: 0.5 },
                    '& hr': { my: 1 },
                  }}
                >
                  <Box textAlign={'center'}>
                    <DynamicView
                      data={facilityInfo.serviceCharacteristic.find((service) => service.name === "GERAL")!.value}
                      labelMap={sideBarGeralLabelDisplay}
                      variant='standard'
                    />
                    <DynamicView
                      data={facilityInfo}
                      labelMap={sideBarDisplay}
                      variant='standard'
                    />
                  </Box>
                  <Divider flexItem />
                  {tracking && (
                    <TaskTrackingDetailCard tracking={tracking} />
                  )}
                  {errorTaskTracking && (
                    <p className='error'>{errorTaskTracking}</p>
                  )}
                  {warningTaskTracking && (
                    <p className='warning'>{warningTaskTracking}</p>
                  )}
                  {!tracking && (
                    <>
                      <Button
                        variant='contained'
                        className='button'
                        onClick={() => searchTaskTracking(facilityInfo.id)}
                        startIcon={<TroubleshootIcon />}
                        disabled={loadingTaskTracking}
                      >
                        {loadingTaskTracking ? 'Verificando...' : 'Verificar Tracking'}
                      </Button>
                    </>
                  )}
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
      {loading && (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 100 }}
          open={loading}
        >
          <CircularWithValueLabel maxSecondsTime={180} interval={5000} />
        </Backdrop>
      )}
    </Box>
  );
};

export default IpInternetDisplay;

