import styles from './MenuDropdown.module.css';
import { Button, Menu, MenuItem, Typography, Divider } from '@mui/material';
import { Link } from 'react-router-dom'; // You'll need to import Link from your router library
import { IMenuItem } from '../interfaces/MenuItem';
import { useEffect, useState } from 'react';
import { useUserContext } from '../contexts/UserContext';

interface Props {
  displayName: string;
  menuItems: IMenuItem[];
  onMenuOpen?: () => void; // Callback function to handle Menu open
  onMenuClose?: () => void; // Callback function to handle Menu close
}

const MenuDropdown = ({ displayName, menuItems, onMenuOpen, onMenuClose }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { userHasRole } = useUserContext();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    if (onMenuOpen) onMenuOpen(); // Call the callback function when the Menu is opened
  };

  const handleClose = () => {
    setAnchorEl(null);
    if (onMenuClose) onMenuClose();
  };

  return (
    <div>
      <Button
        aria-controls="menu"
        aria-haspopup="true"
        onClick={handleClick}
        sx={{ my: 2, color: 'inherit', display: 'block' }}
      >
        {displayName}
      </Button>
      <Menu
        id="menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className={styles.menu}
        // anchorReference='anchorPosition'
        // anchorPosition={{ top: 81, left: 110 }}
        slotProps={{ paper: { style: { borderBottomLeftRadius: '20px', borderBottomRightRadius: '20px' } } }}
      >
        {menuItems.map((categoryItem, index) => (
          <div style={{ display: 'inherit' }} key={index}>
            {categoryItem.items.length > 0 && (
              <div>
                <MenuItem
                  className={styles.menu_category}
                  disabled
                  sx={{
                    "&.Mui-disabled": {
                      opacity: 1
                    }
                  }}
                >
                  {categoryItem.category}
                </MenuItem>
                {categoryItem.items.map((menuItem, itemIndex) => {
                  if (!menuItem.allowedRoles || userHasRole(menuItem.allowedRoles)) {
                    return (
                      <MenuItem
                        className={styles.menu_item}
                        key={itemIndex}
                        onClick={handleClose}
                        component={Link} to={menuItem.link}
                        disabled={menuItem.disabled}
                      >
                        <Typography>
                          {menuItem.text}
                          {menuItem.new && (
                            <span className={styles.highlight}>novo</span>
                          )}
                          {menuItem.disabled && (
                            <span className={styles.highlight}>Em breve</span>
                          )}
                        </Typography>
                      </MenuItem>
                    );
                  }
                })}
              </div>
            )}
            <Divider orientation='vertical' flexItem variant='middle' />
          </div>
        ))}
      </Menu>
    </div>
  );
};

export default MenuDropdown;